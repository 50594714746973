export function formatPhone(phone: string): string {
  // Городские номера
  if (phone.startsWith('+78') || phone.startsWith('88')) {
    return phone.replace(
      /(\+7|8)(\d{4})(\d{3})(\d{3})/g,
      '$1 ($2) $3-$4',
    );
  }

  // Мобильные номера
  return phone.replace(
    /(\+7|8)(\d{3})(\d{3})(\d{2})(\d{2})/g,
    '$1 ($2) $3-$4-$5',
  );
}
