/* eslint-disable no-console */
import Vue from 'vue';
import {
  register,
} from 'register-service-worker';

function showNotification(): void {
  Vue.notify({
    group: 'service-worker-notification',
    type: 'success',
    duration: -1,
    title: 'Доступна новая версия приложения',
    text: 'Нажмите кнопку <b>Обновить</b> чтобы установить новую версию.',
    data: {
      button: {
        text: 'Обновить',
      },
    },
  });
}

const swUrl = `${process.env.BASE_URL}sw.js`;

if (process.env.NODE_ENV === 'production') {
  register(swUrl, {
    ready() {
      console.log(
        'Приложение обслуживается из кеша сервис-воркером.\n' +
          'Подробнее здесь: https://goo.gl/AFskqB',
      );
    },
    registered(sw) {
      console.log('Сервис-воркер зарегистрирован');

      sw.update();
    },
    cached() {
      console.log('Контент кэширован для использования в автономном режиме');
    },
    updatefound() {
      console.log('Новый контент загружен');
    },
    updated() {
      console.log('Сервис-воркер обновлён');

      if (window.navigator.onLine) {
        showNotification();
      } else {
        window.addEventListener('online', () => {
          showNotification();
        }, { once: true });
      }
    },
    offline() {
      console.log('Нет интернет соединения. Приложение работает в автономном режиме.');
    },
    error(error) {
      console.error('Ошибка при регистрации сервис-воркера:', error);
    },
  });
}
