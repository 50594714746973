
































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import AppHeader from '@/layout/AppHeader.vue';
import AppFooter from '@/layout/AppFooter.vue';
import {
  clearCache,
} from '@/utils';

@Component({
  components: {
    AppHeader,
    AppFooter,
  },
})
export default class App extends Vue {
  // eslint-disable-next-line class-methods-use-this
  public async locationReload(): Promise<void> {
    await clearCache();
    window.location.reload(true);
  }
}
