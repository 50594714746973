






















































import {
  Component, Vue,
} from 'vue-property-decorator';
import SocialButtons from '../components/SocialButtons.vue';

@Component({
  components: {
    SocialButtons,
  },
})
export default class AppFooter extends Vue {
  public get contactEmail(): string {
    return process.env.VUE_APP_CONTACT_EMAIL;
  }
}
