




















import {
  Component,
  Vue,
} from 'vue-property-decorator';

interface SharingInterface {
  url: string
  title: string
  description: string
  hashtags?: string
}

interface NetworkInterface {
  network: string
  name: string
  icon: string[]
  color?: string
}

@Component
export default class SocialButtons extends Vue {
  public get siteUrl(): string {
    return `https://${process.env.VUE_APP_LOCALIZED_DOMAIN}`;
  }

  public get networks(): NetworkInterface[] {
    return [
      { network: 'odnoklassniki', name: 'Odnoklassniki', icon: ['fab', 'odnoklassniki'], color: '#ed812b' },
      { network: 'vk', name: 'Vk', icon: ['fab', 'vk'], color: '#4a76a8' },
      { network: 'telegram', name: 'Telegram', icon: ['fab', 'telegram'], color: '#0088cc' },
      { network: 'twitter', name: 'Twitter', icon: ['fab', 'twitter'], color: '#1da1f2' },
      // { network: 'facebook', name: 'Facebook', icon: ['fab', 'facebook'], color: '#1877f2' },
      { network: 'whatsapp', name: 'Whatsapp', icon: ['fab', 'whatsapp'], color: '#25d366' },
      { network: 'email', name: 'Email', icon: ['fa', 'envelope'], color: '#ff8c00' },
    ];
  }

  public get sharing(): SharingInterface {
    return {
      url: this.siteUrl,
      title: 'Турбазы Донецка',
      description: 'Каталог турбаз города Донецка Ростовской области',
    };
  }
}
