import {
  RouteConfig,
} from 'vue-router';

const appName = 'Турбазы Донецка';
const appUrl = `https://${process.env.VUE_APP_DOMAIN}`;

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      breadCrumb: 'Главная',
      metaInfo: {
        title: appName,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: 'Какой тип отдыха способен подарить человеку самые положительные эмоции и море приятных воспоминаний? Выезд на природу! Только на природе человек способен сбросить груз ежедневных проблем, которые долго разъедали его изнутри. Только покинув знакомую обстановку, можно восстановить пошатнувшееся душевное равновесие и по-настоящему отдохнуть.',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: appUrl,
          },
        ],
      },
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home/index.vue'),
  },
  {
    path: '/catalog',
    name: 'Catalog',
    meta: {
      breadCrumb: 'Каталог',
      metaInfo: {
        title: `${appName} - Каталог`,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: 'В каталоге, кроме основных сведений о туристических базах, также указываются отзывы клиентов о каждой базе отдыха. Ознакомившись с ними, вы узнаете, насколько высоко качество обслуживания на каждой отдельной турбазе, какое состояние номеров и другие полезные сведения.',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: `${appUrl}/catalog`,
          },
        ],
      },
    },
    component: () =>
      import(/* webpackChunkName: "catalog" */ '../views/Catalog/index.vue'),
  },
  {
    path: '/catalog/:slug',
    name: 'Hostel',
    meta: {
      breadCrumb: 'Турбаза {{title}}',
      metaInfo: {
        title: `${appName} - Турбаза {{title}}`,
        meta: [],
        link: [
          {
            rel: 'canonical',
            href: `${appUrl}/catalog/{{slug}}`,
          },
        ],
      },
    },
    component: () =>
      import(/* webpackChunkName: "hostel" */ '../views/Catalog/Hostel/index.vue'),
  },
  {
    path: '/taxi',
    name: 'Taxi',
    meta: {
      breadCrumb: 'Вызвать такси',
      metaInfo: {
        title: `${appName} - Вызвать такси`,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: 'Каталог служб такси города Донецк Ростовской области.',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: `${appUrl}/taxi`,
          },
        ],
      },
    },
    component: () =>
      import(/* webpackChunkName: "taxi" */ '../views/Taxi/index.vue'),
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      breadCrumb: 'О проекте',
      metaInfo: {
        title: `${appName} - О проекте`,
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: 'Создавая наш сервис, мы руководствовались одним убеждением: делая что-то для людей, делай так, словно ты создаешь это для себя самого. Поэтому немалое внимание было уделено разработке удобного интерфейса, ориентирование в котором осуществлялось бы интуитивно и понятно даже для неопытных пользователей интернета.',
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: `${appUrl}/about`,
          },
        ],
      },
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About/index.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      breadCrumb: 'Страница не найдена',
      metaInfo: {
        title: `${appName} - Страница не найдена`,
        meta: [],
        link: [],
      },
    },
    component: () =>
      import(/* webpackChunkName: "not-found" */ '../views/404/index.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];
