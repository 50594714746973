import 'loading-attribute-polyfill';
import Vue from 'vue';
import '@/plugins/vue-mq';
import '@/plugins/inkline';
import '@/plugins/vue-notification';
import '@/plugins/vue-social-sharing';
import '@/plugins/fontawesome';
import '@/plugins/vue-meta';
import '@/plugins/vue-yandex-maps';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/registerServiceWorker';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
