import Vue from 'vue';

import {
  library,
} from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faExternalLinkAlt,
  faPhone,
  faParking,
  faUtensils,
  faUtensilSpoon,
  faShoppingBasket,
  faShip,
  faBowlingBall,
  faHotTub,
  faSwimmer,
  faMale,
  faBaby,
  faChild,
  faHome,
  faUmbrellaBeach,
  faWater,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faVk,
  faFacebook,
  faOdnoklassniki,
  faTelegram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  FontAwesomeIcon,
} from '@fortawesome/vue-fontawesome';

library.add(
  // common
  faEnvelope,
  faExternalLinkAlt,
  faPhone,
  faParking,
  faUtensils,
  faUtensilSpoon,
  faShoppingBasket,
  faShip,
  faBowlingBall,
  faHotTub,
  faSwimmer,
  faMale,
  faBaby,
  faChild,
  faHome,
  faUmbrellaBeach,
  faWater,
  // brands
  faTwitter,
  faVk,
  faFacebook,
  faOdnoklassniki,
  faTelegram,
  faWhatsapp,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
